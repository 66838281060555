import React from 'react';
import './ScheduleCallSection.scss';
import {SECTION_ID} from "./ContactSection";
import {scrollTo} from "../../utils/scrollTo";

const ScheduleCallSection = () => {

    const onClick = (e) => {
        scrollTo(SECTION_ID)(e)
    }

    return (
        <section className='section schedule-call-section'>
            <div className="container has-text-centered">
                <div className='schedule-call-section__container'>
                    <p className='is-size-3 is-size-2-desktop has-text-weight-semibold mb-5 has-text-color-gray'>You
                        need to start in 2&nbsp;weeks?</p>
                    <button className='button is-large is-primary cta-button' style={{padding: '2rem'}}
                            onClick={onClick}>Schedule a zoom call!
                    </button>
                </div>
            </div>
        </section>
    );
};

export default ScheduleCallSection;
