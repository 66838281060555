import * as React from "react"
import {ReactNode, useState} from "react"
import './layout.scss'
import '../scss/all.scss';
import {SECTION_ID as LOCATION_SECTION_ID} from "./Section/LocationSection";
import {SECTION_ID as SERVICES_SECTION_ID} from "./Section/ServicesSection";
import {SECTION_ID as CULTURE_SECTION_ID} from "./Section/CultureSection";
import {SECTION_ID as CONTACT_SECTION_ID} from "./Section/ContactSection";
import joinClassNames from "../utils/joinClassNames";
import {StaticImage} from "gatsby-plugin-image";
import {scrollTo} from "../utils/scrollTo";

const Layout = ({children}) => {

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const toggleMenu = () => {
        setIsMenuOpen((prevOpen) => !prevOpen);
    }

    return (
        <>
            <header>
                <nav className={joinClassNames('navbar is-transparent navigation', isMenuOpen && 'has-dark-background')}
                     role="navigation"
                     aria-label="main navigation">
                    <div className={'navbar-brand is-hidden-tablet'}>
                        <div className={joinClassNames('navigation__brand', isMenuOpen ? '' : 'is-hidden')}>
                            <StaticImage
                                src="../images/logo.png"
                                loading="eager"
                                formats={["auto", "webp", "avif"]}
                                alt="iTEAMly"
                                layout={'fixed'}
                                height={32}
                                objectFit={'contain'}
                            />
                        </div>
                        <a role="button" className={joinClassNames('navbar-burger', isMenuOpen && 'is-active')}
                           aria-label="menu" aria-expanded="false" onClick={toggleMenu} style={{color: '#ffffff'}}>
                            <span aria-hidden="true"/>
                            <span aria-hidden="true"/>
                            <span aria-hidden="true"/>
                        </a>
                    </div>
                    <div className={joinClassNames('navbar-menu navigation__menu', isMenuOpen && 'is-active')}>
                        <NavLink id={LOCATION_SECTION_ID} hideMenu={toggleMenu}>
                            Location
                        </NavLink>
                        <NavLink id={SERVICES_SECTION_ID} hideMenu={toggleMenu}>
                            Services
                        </NavLink>
                        <NavLink id={CULTURE_SECTION_ID} hideMenu={toggleMenu}>
                            About us
                        </NavLink>
                        <NavLink id={CONTACT_SECTION_ID} hideMenu={toggleMenu}>
                            Contact
                        </NavLink>
                    </div>
                </nav>
            </header>
            <main>{children}</main>
            <footer className={'footer'}>
                <div className="content has-text-centered is-light">
                    Copyright © {new Date().getFullYear()} iTEAMly
                </div>
            </footer>
        </>
    )
}

type NavLinkProps = {
    id: string,
    children: ReactNode
    hideMenu(): void
}

const NavLink = ({id, children, hideMenu}: NavLinkProps) => {

    const onClick = (e: React.MouseEvent) => {
        scrollTo(id)(e);
        hideMenu()
    }

    return (
        <a className="navbar-item is-uppercase is-size-5-desktop" href={`#${id}`} onClick={onClick}>
            {children}
        </a>
    )
}

export default Layout
