import React, {ReactNode} from "react";
import './Background.scss';
import joinClassNames from "../../utils/joinClassNames";

type Props = {
    position: 'top' | 'bottom'
    children: ReactNode
}

const Background = ({children, position}: Props) => {
    return (
        <div className={joinClassNames('layout-background', `is-${position}`)}>
            {children}
        </div>
    )
}

export default Background;
