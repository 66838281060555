import React, {ReactNode} from 'react';

type Props = {
    email: string,
    children?: ReactNode
    className?: string
}

const EmailLink = ({email, children, className}: Props) => {
    return (
        <a href={`mailto:${email}`} className={className}>
            {children || email}
        </a>
    );
};


export default EmailLink;
