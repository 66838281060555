import React from 'react';
import './LocationSection.scss';
import IconBox from "../IconBox/IconBox";
import {graphql, useStaticQuery} from "gatsby";
import {Highlight, Text} from "../Text/Text";

export const SECTION_ID = 'location'

const LocationSection = () => {

    const {developers, students, engineers, security, communication, location} = useStaticQuery(graphql`
        query {
            developers: file(relativePath: { eq: "location/developers.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            engineers: file(relativePath: { eq: "location/engineers.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            communication: file(relativePath: { eq: "location/communication.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            location: file(relativePath: { eq: "location/location.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            security: file(relativePath: { eq: "location/security.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            students: file(relativePath: { eq: "location/students.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
        }
    `)

    return (
        <section className='section location-section' id={SECTION_ID}>
            <div className="container has-text-centered">
                <h3 className='has-text-primary has-text-weight-semibold is-size-5 is-size-4-desktop mb-3 is-uppercase has-letter-spacing' style={{letterSpacing: '0.1em'}}>Located in Krakow, Poland</h3>
                <p className='has-text-dark has-text-weight-bold is-size-3 is-size-2-desktop mb-6'>the European tech center</p>
                <div className={'location-section__grid'}>
                    <IconBox imageData={developers.childImageSharp.gatsbyImageData} alt={'developers'}
                             className={'has-background-color-light'}>
                        <Text><Highlight>254K</Highlight> software developers</Text>
                        <Text>(25% of CEE Pool)</Text>
                    </IconBox>
                    <IconBox imageData={students.childImageSharp.gatsbyImageData} alt={'students'}
                             className={'has-background-color-light'}>
                        <Text><Highlight>68K</Highlight> of ICT students</Text>
                        <Text>every year</Text>
                    </IconBox>
                    <IconBox imageData={engineers.childImageSharp.gatsbyImageData} alt={'engineers'}
                             className={'has-background-color-light'}>
                        <Text>Top notch engineers:</Text>
                        <Text><Highlight>#3</Highlight> HackerRank,</Text>
                        <Text><Highlight>#5</Highlight> TopCoder</Text>
                    </IconBox>
                    <IconBox imageData={security.childImageSharp.gatsbyImageData} alt={'security'}
                             className={'has-background-color-light'}>
                        <Text>Compliant with strict</Text>
                        <Text>EU regulations</Text>
                    </IconBox>
                    <IconBox imageData={communication.childImageSharp.gatsbyImageData} alt={'communication'}
                             className={'has-background-color-light'}>
                        <Text>Fluent in English</Text>
                    </IconBox>
                    <IconBox imageData={location.childImageSharp.gatsbyImageData} alt={'location'}
                             className={'has-background-color-light'}>
                        <Text>World class infrastructure</Text>
                    </IconBox>
                </div>
            </div>
        </section>
    );
};

export default LocationSection;
