import React, {CSSProperties, HTMLAttributes, ReactNode} from 'react';
import './ServicesSection.scss';
import {Text} from "../Text/Text";
import {StaticImage} from "gatsby-plugin-image";

export const SECTION_ID = 'services'

const ServicesSection = () => {
    return (
        <section className='section services-section' id={SECTION_ID}>
            <div className="container has-text-centered">
                <h3 className='has-text-primary has-text-weight-semibold is-size-5 is-size-4-desktop mb-3 is-uppercase has-letter-spacing'>Our</h3>
                <p className='has-text-dark has-text-weight-semibold is-size-3 is-size-2-desktop mb-6'>Services</p>
                <div className={'services-section__grid'}>
                    <ServicesBox gridStyles={{gridTemplateColumns: 'repeat(2, 1fr)'}} text={
                        <>
                            <Text>Cloud architecture</Text>
                            <Text>Audits & consultancy</Text>
                        </>
                    }>
                        <Logo style={{gridColumn: '1 / span 3'}}>
                            <StaticImage src={'../../images/service/google_cloud.png'} alt={'google_cloud_logo'} placeholder={'tracedSVG'}/>
                        </Logo>
                        <Logo style={{gridColumn: '4 / span 3'}}>
                            <StaticImage src={'../../images/service/azure.png'} alt={'azure_logo'}/>
                        </Logo>
                        <Logo style={{gridColumn: '1 / span 6'}}>
                            <StaticImage src={'../../images/service/aws.png'} alt={'aws_logo'}/>
                        </Logo>
                    </ServicesBox>
                    <ServicesBox text={
                        <>
                            <Text>Application</Text>
                            <Text>development & design</Text>
                        </>
                    }>
                        <Logo style={{gridColumn: '1 / span 2'}}>
                            <StaticImage src={'../../images/service/react.png'} alt={'react_logo'}/>
                        </Logo>
                        <Logo style={{gridColumn: '3 / span 2'}}>
                            <StaticImage src={'../../images/service/angular.png'} alt={'angular_logo'}/>
                        </Logo>
                        <Logo style={{gridColumn: '5 / span 2'}}>
                            <StaticImage src={'../../images/service/go.png'} alt={'go_logo'}/>
                        </Logo>
                        <Logo style={{gridColumn: '1 / span 3'}}>
                            <StaticImage src={'../../images/service/java.png'} alt={'java_logo'}/>
                        </Logo>
                        <Logo style={{gridColumn: '4 / span 3'}}>
                            <StaticImage src={'../../images/service/net.png'} alt={'net_logo'}/>
                        </Logo>
                    </ServicesBox>
                    <ServicesBox text={
                        <>
                            <Text>Setting up IT</Text>
                            <Text>engineering center</Text>
                        </>
                    }>
                        <Logo style={{gridColumn: '1 / span 2'}}>
                            <StaticImage src={'../../images/service/devops.png'} alt={'devops_icon'}/>
                        </Logo>
                        <Logo style={{gridColumn: '3 / span 2'}}>
                            <StaticImage src={'../../images/service/agile.png'} alt={'agile_icon'}/>
                        </Logo>
                        <Logo style={{gridColumn: '5 / span 2'}}>
                            <StaticImage src={'../../images/service/lean.png'} alt={'lean_icon'}/>
                        </Logo>
                    </ServicesBox>
                </div>
            </div>
        </section>
    );
};

type ServicesBoxProps = {
    text: ReactNode
    children: ReactNode
    gridStyles?: CSSProperties
}

const ServicesBox = ({text, children, gridStyles}: ServicesBoxProps) => {
    return (
        <div className={'services-section__box'}>
            {text}
            <div className={'services-section__box__grid mt-6'} style={gridStyles}>
                {children}
            </div>
        </div>
    )
}

type LogoProps = HTMLAttributes<HTMLDivElement> & {
    children: ReactNode,
}

const Logo = ({children, ...attributes}: LogoProps) => {
    return (
        <div className={'services-section__box__grid__item'} {...attributes}>
            {children}
        </div>
    )
}

export default ServicesSection;
