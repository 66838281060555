import React, {FunctionComponent} from 'react';

export const Text: FunctionComponent = ({children}) => {
    return (
        <span className='is-block is-uppercase is-size-5-desktop has-letter-spacing has-text-weight-semibold'>
            {children}
        </span>
    )
}

export const Highlight: FunctionComponent = ({children}) => {
    return (
        <span className='has-text-primary has-text-weight-semibold'>{children}</span>
    )
}
