import React from 'react';
import './HeroSection.scss';
import {StaticImage} from "gatsby-plugin-image";
import {SECTION_ID} from "./ContactSection";
import {scrollTo} from "../../utils/scrollTo";

const HeroSection = () => {
    return (
        <section className='hero is-large hero-section' style={{display: 'grid'}}>
            <StaticImage
                src="../../images/hero.png"
                loading="eager"
                formats={["auto", "webp", "avif"]}
                alt=""
                style={{gridArea: '1/1', width: '100vw', opacity: '0.7'}}
            />
            <div className="container has-text-centered is-centered"
                 style={{
                     gridArea: '1/1',
                     display: "flex",
                     flexDirection: 'column',
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding: '5rem 1rem'
                 }}>
                <StaticImage
                    src="../../images/logo.png"
                    loading="eager"
                    formats={["auto", "webp", "avif"]}
                    alt="iTEAMly"
                />
                <p className='has-text-white has-text-weight-semibold is-size-3 is-size-2-desktop mt-6 mb-1'>
                    Managed software engineering&nbsp;teams
                </p>
                <p className='has-text-white is-size-4-desktop mt-1'>
                    <span className='is-block'>built with top technical talents&nbsp;and&nbsp;led&nbsp;by&nbsp;experienced</span>
                    <span className='is-block'>managers. Focus on your business and</span>
                    <span className='is-block'>let us take care of the technology.</span>
                </p>
                <button className='button is-large is-primary cta-button hero-section__button mt-6' onClick={scrollTo(SECTION_ID)}>
                    Contact us
                </button>
            </div>
        </section>
    );
};

export default HeroSection;
