import React, {FunctionComponent, ReactNode} from 'react';
import './CultureSection.scss'
import {StaticImage} from "gatsby-plugin-image";

export const SECTION_ID = 'culture'

const CultureSection = () => {
    return (
        <section className='section culture-section' id={SECTION_ID}>
            <div className="container has-text-centered">
                <h3 className='has-text-primary has-text-weight-semibold is-size-5 is-size-4-desktop mb-3 is-uppercase has-letter-spacing'>We
                    believe in the power of</h3>
                <p className='has-text-dark has-text-weight-semibold is-size-3 is-size-2-desktop mb-6'>Culture</p>
                <div className={'culture-section__grid'}>
                    <CultureBox image={<StaticImage src={'../../images/culture/engineering.png'} alt={'engineering_excellence'}/>}>
                        <CultureText>value oriented</CultureText>
                        <CultureText>engineering excellence</CultureText>
                    </CultureBox>
                    <CultureBox image={<StaticImage src={'../../images/culture/ownership.png'} alt={'high_degree_of_ownership'}/>}>
                        <CultureText>High degree</CultureText>
                        <CultureText>of ownership</CultureText>
                    </CultureBox>
                    <CultureBox image={<StaticImage src={'../../images/culture/teamwork.png'} alt={'teamwork'}/>}>
                        <CultureText>Teamwork appreciating</CultureText>
                        <CultureText>individualities</CultureText>
                    </CultureBox>
                    <CultureBox image={<StaticImage src={'../../images/culture/learning.png'} alt={'learning_and_development'}/>}>
                        <CultureText>hungry for</CultureText>
                        <CultureText>learning & development</CultureText>
                    </CultureBox>
                    <CultureBox image={<StaticImage src={'../../images/culture/startup.png'} alt={'startup_vibe'}/>}>
                        <CultureText>Startup</CultureText>
                        <CultureText>vibe</CultureText>
                    </CultureBox>
                </div>
            </div>
        </section>
    );
};

type CultureBoxProps = {
    image: ReactNode
    children: ReactNode
}

export const CultureText: FunctionComponent = ({children}) => {
    return (
        <span className='is-block is-uppercase is-size-6-desktop has-text-weight-semibold has-text-color-purple'>
            {children}
        </span>
    )
}

const CultureBox = ({image, children}: CultureBoxProps) => {
    return (
        <div className={'culture-section__grid__item'}>
            <div className='culture-section__grid__item__icon'>
                {image}
            </div>
            <p>
                {children}
            </p>
        </div>
    )
}

export default CultureSection;
