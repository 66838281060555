import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LocationSection from "../components/Section/LocationSection";
import BrandSection from "../components/Section/BrandSection";
import ServicesSection from "../components/Section/ServicesSection";
import TestimonialsSection from "../components/Section/TestimonialsSection";
import ScheduleCallSection from "../components/Section/ScheduleCallSection";
import CultureSection from "../components/Section/CultureSection";
import Background from "../components/Background/Background";
import ContactSection from "../components/Section/ContactSection";
import HeroSection from "../components/Section/HeroSection";
import TrustedBySection from "../components/Section/TrustedBySection";

const IndexPage = () => (
    <Layout>
        <Seo/>
        <HeroSection/>
        <LocationSection/>
        <BrandSection/>
        <Background position={'top'}>
            <ServicesSection/>
            <TrustedBySection/>
            <ScheduleCallSection/>
        </Background>
        <Background position={'bottom'}>
            <CultureSection/>
            <TestimonialsSection/>
            <ContactSection/>
        </Background>
    </Layout>
)

export default IndexPage
