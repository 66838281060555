import React from 'react';
import './BrandSection.scss';
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const BrandSection = () => {

    const brands = useStaticQuery(graphql`
        query {
            google: file(relativePath: { eq: "brand/google.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            ibm: file(relativePath: { eq: "brand/ibm.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            motorola: file(relativePath: { eq: "brand/motorola.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
            ubs: file(relativePath: { eq: "brand/ubs.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FIXED)
                }
            }
        }
    `)

    return (
        <section className='section brand-section'>
            <div className="container has-text-centered">
                <h3 className='has-text-dark has-text-weight-semibold is-size-3 is-size-2-desktop mb-3'>Big brands</h3>
                <p className='has-text-white has-text-weight-semibold is-size-5 is-size-4-desktop mb-6 is-uppercase has-letter-spacing'>
                    opened R&D center here
                </p>
                <div className={'brand-section__grid'}>
                    {Object.keys(brands).map((brand) => (
                        <div key={brand} className={'brand-section__grid__item'}>
                            <GatsbyImage
                                image={brands[brand].childImageSharp.gatsbyImageData}
                                alt={`${brand}_logo`}
                                loading="eager"
                                quality={95}
                                layout={'fixed'}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BrandSection;
