import React, {ReactNode} from 'react';
import './TestimonialsSection.scss';
import {StaticImage} from "gatsby-plugin-image";
import joinClassNames from "../../utils/joinClassNames";

const TestimonialsSection = () => {
    return (
        <section className='section testimonials-section'>
            <div className="container has-text-centered">
                <h3 className='has-text-primary has-text-weight-semibold is-size-5 is-size-4-desktop mb-3 is-uppercase has-letter-spacing'>Word
                    of mouth</h3>
                <p className='has-text-dark has-text-weight-semibold is-size-4 is-size-3-desktop mb-6'>Of those who
                    trusted teams in our group</p>
                <div className="testimonials-section__content">
                    <div className='testimonials-section__testimonials'>
                        <Testimonial message={'They were very responsive and very easy to work with.'}>
                            <StaticImage src={'../../images/client/roche.png'} alt={'Roche_logo'}/>
                        </Testimonial>
                        <Testimonial
                            message={'They were particularly cooperative and have a good grasp of our business.'}>
                            <StaticImage src={'../../images/client/ca.png'} alt={'CA_logo'}/>
                        </Testimonial>
                        <Testimonial message={'Their assistance went beyond the initial scope of work.'}>
                            <StaticImage src={'../../images/client/job_street.png'} alt={'Job_street_logo'}/>
                        </Testimonial>
                    </div>
                </div>
            </div>
        </section>
    );
};

type TestimonialProps = {
    message: string
    children: ReactNode
}

const Testimonial = ({message, children}: TestimonialProps) => {
    return (
        <div className='testimonials-section__testimonials__item'>
            <div className='testimonials-section__testimonials__item__icon'>
                <StaticImage src={'../../images/quote.png'} alt={''}/>
            </div>
                <p className={joinClassNames('mr-6 is-size-5 has-text-color-dark-gray testimonials-section__testimonials__item__text')}><i>{message}</i></p>
                <div className={'testimonials-section__testimonials__item__logo'}>
                    {children}
                </div>
        </div>
    )
}

export default TestimonialsSection;
