import React, {FunctionComponent} from 'react';
import './TestimonialsSection.scss';
import {StaticImage} from "gatsby-plugin-image";
import './TrustedBySection.scss';

const TrustedBySection = () => {
    return (
        <section className='section trusted-by-section'>
            <div className="container has-text-centered">
                <h3 className='has-text-dark has-text-weight-semibold is-size-3 is-size-2-desktop mb-6'>Trusted by</h3>
                <div className='trusted-by-section__trusted'>
                    <Logo>
                        <StaticImage src={'../../images/client/uber.png'} alt={'uber_logo'}/>
                    </Logo>
                    <Logo>
                        <StaticImage src={'../../images/client/smallpdf.png'} alt={'smallpdf_logo'}/>
                    </Logo>
                    <Logo>
                        <StaticImage src={'../../images/client/roche.png'} alt={'roche_logo'}/>
                    </Logo>
                    <Logo>
                        <StaticImage src={'../../images/client/lyyti.png'} alt={'lyyti_logo'}/>
                    </Logo>
                    <Logo>
                        <StaticImage src={'../../images/client/tyxit.png'} alt={'tyxit_logo'}/>
                    </Logo>
                </div>
            </div>
        </section>
    );
};

const Logo: FunctionComponent = ({children}) => {
    return (
        <div className={'trusted-by-section__trusted__logo'}>
            {children}
        </div>
    )
}

export default TrustedBySection;
