import React, {HTMLAttributes, ReactNode} from 'react';
import './IconBox.scss';
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";
import joinClassNames from "../../utils/joinClassNames";

type Props = HTMLAttributes<HTMLDivElement> & {
    imageData: IGatsbyImageData
    alt: string,
    children: ReactNode
}

const IconBox = ({imageData, alt, children, className, ...attributes}: Props) => {

    return (
        <div {...attributes} className={joinClassNames(className, 'icon-box')} style={{padding: '1.5rem 3rem'}}>
            <GatsbyImage
                image={imageData}
                alt={alt}
                loading="eager"
                quality={95}
                layout={'fixed'}
            />
            <p className={joinClassNames('icon-box__content')}>
                {children}
            </p>
        </div>
    );
};

export default IconBox;
