import React, {ReactNode} from 'react';

type Props = {
    phone: string,
    children?: ReactNode
    className?: string
}

const PhoneLink = ({phone, children, className}: Props) => {

    return (
        <a href={`tel:${phone}`} className={className}>
            {children || phone}
        </a>
    );
};

export default PhoneLink;
